import { DocumentSnapshot } from "../firestoreImports"
import { z } from "zod"

const fleetSchema = z.object({
    title: z.string()
})

interface IFleet {
    id: string
    title: string
}
export interface Fleet extends IFleet {}
export class Fleet {
    constructor(params: IFleet) {
        Object.assign(this, params)
    }

    static fromData(doc: DocumentSnapshot): Fleet | null {
        const data = doc.data()
        if (!data) {
            console.warn("Cannot create Fleet from snapshot")
            return null
        }

        try {
            const parsed = fleetSchema.parse(data)
            return new Fleet({
                id: doc.id,
                title: parsed.title
            })
        } catch (error) {
            console.log(`Cannot create fleet ${doc.id} from snapshot: ${error}`)
            return null
        }
    }
}
