import moment from "moment-timezone"

import { DocumentSnapshot, GeoPoint } from "../firestoreImports"

export enum ReservationType {
    Day,
    Halfday,
    Hours,
    Minutes
}
export class Location {
    constructor(
        public code: string,
        public title: string,
        public hoursPreceding: number,
        public timezone: string,
        public reservationEnabled: boolean,
        public reservationType: ReservationType,
        public hoursPrecedingPattern: number[],
        public lat?: number,
        public lng?: number
    ) {}

    static fromData(doc: DocumentSnapshot): Location | null {
        if (!doc.data()) {
            console.debug("Cannot create Location from snapshot")
            return null
        }
        const data = doc.data()!
        const timezone = data.timezone ?? "Europe/Prague"
        const hoursPreceding = data.hoursPreceding ?? 4
        const title = data.title ?? doc.id
        const reservationEnabled = data.reservationEnabled !== undefined ? data.reservationEnabled : true
        const reservationType = data.reservationType ?? ReservationType.Day
        const coords = data.location !== undefined ? (data.location as GeoPoint) : undefined
        const hoursPrecedingPattern = data.hoursPrecedingPattern ?? Array(7).fill(hoursPreceding)
        return new Location(
            doc.id,
            title,
            hoursPreceding,
            timezone,
            reservationEnabled,
            reservationType,
            hoursPrecedingPattern,
            coords?.latitude,
            coords?.longitude
        )
    }

    assignmentDateForDate = (date: Date) => {
        const forWeekday = moment.tz(date, this.timezone).isoWeekday()
        const hoursPreceding = this.hoursPrecedingPattern[forWeekday - 1] ?? this.hoursPreceding

        const extraDaysPreceding = Math.floor(hoursPreceding / 24)

        return moment
            .tz(date, this.timezone)
            .startOf("day")
            .subtract(hoursPreceding % 24, "hours")
            .subtract(extraDaysPreceding, "days")
            .toDate()
    }

    assignmentDoneForDate = (date: Date) => {
        return moment().isSameOrAfter(this.assignmentDateForDate(date))
    }
}
