import { DocumentSnapshot, Timestamp } from "../firestoreImports"

export enum EngineType {
    Gasoline = 0,
    Electric,
    NaturalGas,
    Diesel,
    Plugin,
    Hydrogen
}
export const allEngineTypes: EngineType[] = Object.values(EngineType).filter(value => typeof value === "number") as EngineType[]

interface CarParameters {
    id: string
    plateNum: string
    timestampCreated?: Date
    brand?: string
    model?: string
    color?: string
    engineType?: EngineType
}
export class Car {
    id: string
    plateNum: string
    timestampCreated?: Date
    brand?: string
    model?: string
    color?: string
    engineType: EngineType
    isElectric: boolean

    constructor(parameters: CarParameters) {
        this.id = parameters.id
        this.plateNum = parameters.plateNum
        this.timestampCreated = parameters.timestampCreated
        this.brand = parameters.brand
        this.model = parameters.model
        this.color = parameters.color
        this.engineType = parameters.engineType ?? EngineType.Gasoline
        this.isElectric = parameters.engineType === EngineType.Electric || parameters.engineType === EngineType.Plugin
    }

    static fromData(doc: DocumentSnapshot): Car | null {
        if (!doc.data()) {
            console.warn("Cannot create Car from snapshot")
            return null
        }
        const data = doc.data()!
        const timestampCreated = data.timestampCreated as Timestamp
        const car = new Car({
            id: doc.id,
            plateNum: data.plateNum,
            timestampCreated: timestampCreated?.toDate(),
            brand: data.brand,
            model: data.model,
            color: data.color,
            engineType: data.engineType ?? (data.isElectric ? EngineType.Electric : EngineType.Gasoline)
        })
        return car
    }

    asData(): { [k: string]: any } {
        return {
            plateNum: this.plateNum,
            timestampCreated: this.timestampCreated ?? new Date(),
            brand: this.brand,
            model: this.model,
            color: this.color,
            engineType: this.engineType
        }
    }

    public toString = (): string => {
        return `${this.brand ?? ""} ${this.model ?? ""} (${this.plateNum})`
    }
}
